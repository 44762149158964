@mixin breakpoint($size) {
    @media (min-width: $size) { @content; }
}

* {
	box-sizing: border-box;
}

body {
	background: $gray;
}
#wrapper {
  overflow: hidden;
}
main.main {
   overflow: hidden;
   padding-top: 87px;
   @include tablet() {
    padding-top: 108px;
   }
}

/*
  Content Sections
 */
.content:not(:last-child) {
   margin-bottom: 0;
}
.content--padded {
  padding: 50px 0;
  @include tablet() {
      padding: 100px 0;
  }

}

.content--section {
   padding: 100px 0;
}

hr {
  background: #bbb;
  margin: 100px 0;
}

.container {
   &.is-wide {
      // margin: 0 20px;
      @include breakpoint(2000px) {
         max-width: 1400px;
         margin: 0 auto;
         width: auto;
      }
   }
}

.container--wide {
   padding-left: 30px;
   padding-right: 30px;
   max-width: 1800px;
   margin: 0 auto;
}

.text-content {
  @include mobile() {
    padding-left: 20px;
    padding-right: 20px;
  }
}


/*
  Modifiers
 */

.page--header {
  padding: 30px 0px 30px 20px;
   color: white;
   margin-bottom: 0;
   background-image: linear-gradient(-127deg, #003952 2%, #0F5582 100%);
   @include tablet() {
    padding: 75px 0;
   }
   h1,h2,h3,h4,h5 {
      color: white;
      font-weight: 700;
      margin: 0;
   }
}

.section--title {
   h1,h2,h3,h4,h5 {
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 100px;
   }
}


.image--header {
   position: relative;
   height: 50vh;
   background-size: cover;
   overflow: hidden;
   img {
    visibility: hidden;
    width: 100%;
   }
   .caption
  {
      position: relative;
      color: white;
      background: rgba($primary, .95);
      position: absolute;
      bottom: 0;
      width: 100%;
      @include tablet()
      {
          bottom: auto;
          top: 0;
          right: 5%;
          width: 500px;
          
          height: 100%;
      }
      @include widescreen() {
        max-width: 30%;
      }
      .caption--inner
      {
          width: 100%;
          padding: 30px;
          h1 {
            font-size: 24px;
             margin-bottom: 1rem;
          }
          h2 {
             font-size: 20px;
             margin-bottom: 1rem;
          }
          @include tablet() {
            padding: 20%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            h1 {
              font-size: 32px;
               margin-bottom: 2rem;
            }
            h2 {
               font-size: 24px;
               margin-bottom: 2rem;
            }
          }
      }
      h1,h2,h3,h4,h5
      {
          color: white;
      }
  }
}
 
.global--news {
  border-top: thin solid #bbb;
}
.text-center{
   text-align: center;
}

.split--content {
   display: flex;
   >div {
      width: 50%;
   }
}




