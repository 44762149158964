div.ccm-panel-content {
	ul.nav {
		display: block;
    height: auto;
    text-align: left;
    padding-bottom: 20px;
	}
}

.ccm-notification-help-launcher {
	top: 158px !important;
}