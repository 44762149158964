ul.breadcrumbs {
	display: flex;
	margin-bottom: 20px;
	li {
		a {
			font-size: 12px;
			padding-right: 10px;
			margin-right: 10px;
			position: relative;
			&:after {
				content : '/';
				position : absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
		&:last-child {
			a {
				&:after {
					content: '';
				}
			}
		}
	}
}