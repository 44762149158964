.home--slider
{
    .slick-slider:not(.slick-initialized) {
        div {
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;
            @include tablet() {
                height: 65vh;   
            }
        }
    }
    img
    {
        width: 100%;
        height: auto;
    }
    .slick-slider
    {
        .slick-slide
        {
            background-size: cover;
            // background-position: top center;
            background-repeat: no-repeat;
            overflow: hidden;
            position: relative;
            height: 75vh;
            @include tablet() {
                height: 65vh;   
            }
            img {
                display: none;
                @include tablet() {
                    display: block;
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: auto;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                
            }
        }
        .caption
        {
            position: relative;
            color: white;
            background: rgba($primary, .95);
             position: absolute;
             bottom: 0;
             width: 100%;
             left: 0;
            @include tablet()
            {
               left: auto;
                top: 0;
                right: 5%;
                width: 500px;
               
                height: 100%;
            }
            @include widescreen() {
                 max-width: 30%;
            }
            .caption--inner
            {
                width: 100%;
                padding: 10%;
                h1,h2 {
                    font-size: 24px;
                }
                h1,h2,h3,h4,h5
                {
                    color: white;
                }
                @include tablet()
                {
                padding: 20%;

                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    h1,h2 {
                        font-size: 32px;
                    }
                }
            }
           
        }



        .slick-dots {
            margin: 0;
        	li {
                width: 50px;
				height: 10px;
                @include tablet() {
                 width: 100px;
                }
        		button {
        			width: 50px;
					height: 10px;
					background: darken($gray, 10%);
                    @include tablet() {
                        width: 100px;
                    }
        			&:before {
        				content : ' ';
        				width: 100px;
        				height: 10px;
        				background: $primary;
        				width: 0;
        				transition: width 0.3s ease;
        			}
        		}
        		&.slick-active{
        			button {
        				&:before {
        					width: 100%;
        					background: $primary;
        				}
        			}
        		}
        	}
        }

        .slick-next {
        	right: 0px;
        	bottom: 0;
        	top: auto;
        	&:before {
        		content: '\f105';
        	}
        }
        .slick-prev {
        	left: 0px;
        	bottom: 0;
        	top: auto;
        	&:before {
        		content: '\f104';
        	}

        }
        .slick-next, .slick-prev{
        	height: auto;transform: translateY(0);
        	bottom: 0;
        	z-index: 10;
        	width: 5%;

				    font-size: inherit;
				    text-rendering: auto;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
        	&:before {
    		    font: normal normal normal 18px/1 FontAwesome;
        		display: block;
        		padding: 20px 0;
        		background: $info;
        	}
        }
    }
}

.home--featured
{
    padding: 100px 0;
    .featured--pod
    {
        position: relative;
        display: block;
        width: 100%;
        transition: opacity .2s ease, transform .2s ease;
        transform: translateY(30px);
        opacity: 0;

        &.in
        {
            transform: translateY(0);

            opacity: 1;
        }
        img
        {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }
        .caption
        {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
            padding-right: 50px;
            transition: all .2s ease;
            color: white;
            background: rgba($primary-accent, .8);
            h3,h4,h5 {
                font-size: 16px;
            }
            h1,h2,h3,h4,h5,a
            {
                font-weight: 400;
                margin-bottom: 0;
                color: rgba(255,255,255,0.9);
            }
            .view--more
            {
                font-size: 12px;
                font-weight: 500;
                line-height: 1;
                font-size: 14px;
                position: absolute;
                top: 50%;
                right: -30px;
                display: inline-block;
                transition: all .2s ease;
                letter-spacing: 2px;
                opacity: 0;
                .fa
                {
                    font-size: 18px;
                    position: absolute;
                    top: 50%;
                    right: -20px;
                    transform: translateY(-50%);
                }
            }
        }
        &:hover
        {
            .caption
            {
                // padding-bottom: 80px;
                padding-right: 80px;
                .view--more
                {
                    right: 30px;
                    transition-delay: .15s;
                    opacity: 1;
                }
            }
        }
    }
}


.home--info
{
    .info--pod
    {
        position: relative;
        height: 80vh;
        max-height: 800px;
        text-align: center;
        color: white;
        background: $primary;
        $colors: #003954, #004263, #004c75, #005482;
        @for $i from 1 through 4 {
    		 &:nth-child(#{$i})
            {
                background: nth($colors, $i); 
                .info--pod-inner
                {
                    transition-delay: #{0.2 * $i}s;
                }
            }
        }
      
        .info--pod-inner
        {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            max-width: 250px;
            transition: transform .3s ease, opacity .3s ease;
            transform: translateX(0%) translateY(-50%);
            opacity: 0;
            @include mobile {
                h3 {
                    font-size: 20px;
                }
            }
            h1,h2,h3,h4,h5
            {
                color: white;
            }
            .icon
            {
                width: 100px;
                height: 100px;
                margin: 0 auto 50px auto;
                padding: 30px;
                border: thin solid $info;
                border-radius: 100px;
                img
                {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    &.in
    {
        .info--pod
        {
            .info--pod-inner
            {
                transform: translateX(-50%) translateY(-50%);
                opacity: 1;
            }
        }
    }
}

.home--worldwide
{
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
    img
    {
        margin: 50px auto;
        @include desktop()
        {
            width: 60%;
        }
    }
}


.home--testimonials
{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 50px rgba(0,0,0,0.4);
    height: 75vh;
    img
    {
        opacity: 0;
        visibility: hidden;
        width: 100%;
    }
    .caption
    {
        position: absolute;
        bottom: 0;
        padding: 80px 50px;
        background: rgba($gray, 0.9);
        @include tablet() {
            bottom: auto;
             top: 50%;
            transform: translateY(-50%);
            right: 10%;
            width: 500px;
        }

        blockquote {
            color:$primary;
        	&:before {
        		content: "\"";
        	}
            &:after {
                content: "\"";
            }
        	position: relative;
        	border-left: 0;
        	background: none;
        	font-size: 17px;
        	font-style: italic;
        	padding: 0;
        	margin-bottom: 40px;
            font-weight: 700;
        }
        .author {
        	font-weight: 700;
        }
    }
}




.news--pod {
	display: block;
	height: 100%;
	position: relative;
    margin-bottom: 30px;
	.description {
		display: block;
		padding: 30px 0;
        @include mobile {
            padding: 20px;
        }
        h4 {
            font-size: 1.2rem;
        }

		.view--more
    {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        bottom: 0;
        left: 0px;
        display: inline-block;
        transition: all .2s ease;
        letter-spacing: 2px;
        @include mobile() {
            left: 20px;
        }
        .fa
        {
            font-size: 24px;
            position: absolute;
            top: 50%;
            right: -20px;
            transform: translateY(-50%);
        }
    }
	}
	&:hover {
		.description {
			.view--more {
				color: $info;
				left: 10px;
			}
		}
	}
}
