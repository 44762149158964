
@import "vars";

@import "../../bower_components/bulma/bulma";

@import "../../bower_components/slick-carousel/slick/slick";
@import "../../bower_components/slick-carousel/slick/slick-theme";

$fa-font-path:        "../fonts/vendor/font-awesome";
@import "../../bower_components/font-awesome/scss/font-awesome";

@import "components/type";
@import "components/global";
@import "components/button";

@import "components/navigation";
@import "components/tabs";
@import "components/sliders";
@import "components/breadcrumbs";
@import "components/form";
@import "components/footer";
@import "components/pagination";
@import "overrides/c5";


@import "pages/home";
@import "pages/products";
@import "pages/product-details";
@import "pages/about-overview";
@import "pages/team";
@import "pages/contact";
@import "pages/news";
@import "pages/industry-facilities";

@import "overrides/ie";
