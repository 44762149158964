.button {
	padding: 15px 30px;
	font-weight: 700;
	letter-spacing: 2px;
	position: relative;
	&.is-large {
		padding: 15px 40px;
		height: 2.5em;
	}
	&.is-full {
		width: 100%;
	}
	&.has-icon {
		padding-left: 50px;
	}
	.icon {
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
	}
	.fa {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}