$gray: #DADADA;
$light-gray: #eee;
$dark-gray: darken($gray, 10%);
$blue: #364F67;

$primary: $blue;
$primary-accent: #005482;
$info: #55BFBD;

$radius-small: 4px;
$radius: 10px;
$radius-large: 14px;

$desktop: 1025px;
$widescreen: 1192px;
$fullhd: 1192px;
