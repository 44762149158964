.products--navigation {
	background: white;
	overflow-x: scroll;
	@include tablet() {
		overflow-x: auto;
	}
	ul.nav {
		background: transparent;
		height: auto;
		@include desktop() {
			transform: translateX(-30px);
		}
		li {
			a {
				display: block;
				padding:20px 22px;
				font-size: 14px;
			}
			&.is-active {
				a {
					font-weight: 700;
				}
			}
		}
	}
}


.products--list {
	@include mobile() {
		margin: 20px;
	}
	.products--list-item {
		margin-bottom: 50px;
		.image {
			img {
				width: 100%;
				height: auto;
			}
		}
		.title {
			display: block;
			position: relative;
			padding-right: 10%;
			margin-bottom: 20px;
			padding: 20px;
			@include tablet() {
				padding: 30px 40px 30px 0px;
			}
			h1,h2,h3,h4 {
				font-size: 18px;
				color: $primary;
				font-weight: 700;
				transition: color 0.15s linear;
				margin-bottom: 0;
				@include tablet() {
					font-size: 20px;
				}
			}
			i.fa {
				position: absolute;
				transition: right 0.15s 0.15s linear, color 0.15s 0.15s linear;
				color: $primary;
				top: 50%;
				line-height: 1;
				right: 10px;
				transform: translateY(-50%);
			}
			&:after, &:before {
				content: ' ';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0%;
				height: 1px;
				transition: width 0.3s linear;
			}
			&:before {
				width: 100%;
				background: darken($gray, 10%);
			}
			&:after {
				background: $primary-accent;
			}
		}
		&:hover {
			
			.title {

				h1,h2,h3,h4,h5 {
					color: $primary-accent;
				}
				i.fa {
					color: $primary-accent;
					right: 0;
				}
				&:after {
				width: 100%;
			}
			}
		}
	}
}