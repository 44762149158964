.form-remodal-inner {
	text-align: left;
	h3 {
		font-size: 32px;
		margin-bottom: 40px;
	}
}

fieldset {
	border: none;
}

.form-group {
	margin-bottom: 20px;
	label {
		display: block;
		margin-bottom: 5px;

	}
	.form-control {
			display: block;
			padding: 10px;
			border: thin solid $gray;
			width: 100%;
			&:focus {
				outline: none;
				border-color: $primary;
			}
		}
}