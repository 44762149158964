.overview--about {
	.column.is-bordered {
		// border-top: thin solid darken($gray, 10%);
		// border-left: thin solid darken($gray, 10%);

		&:last-child {
			// border-right: thin solid darken($gray, 10%);
		}

	}
	.pod--container {
		.pod {
					.description {
						transition: all 0.2s ease;
						opacity: 0;
						transform: translateY(-30px);
					}
				}
			.column.is-bordered {
				
				&:nth-child(1) .pod { .description{transition-delay: 0.15s;} background: $primary;}
				&:nth-child(2) .pod { .description{transition-delay: 0.3s;} background: darken($primary, 3%);}
				&:nth-child(3) .pod { .description{transition-delay: 0.45s;} background: darken($primary, 6%);}
				&:nth-child(4) .pod { .description{transition-delay: 0.45s;} background: darken($primary, 9%);}
			}
			&.in {
				.pod {
						.description {
							opacity: 1;
							transform: translateY(0px);
						}
					
				}
			}
		}

	.pod {
		height: 100%;
		.description {
			padding: 50px 50px 100px;
			color: white;
		}
		// padding: 50px 50px 100px;
		h2,h3,h4 {
			margin-bottom: 30px;
			font-size:24px;
			color: white;
		}
	}
}

.overview--intro {
	h2,h3,h4 {
		font-size: 1.6rem;
	}
}


.about--company {
	background: #eeeeee;
	padding: 100px 0;

	.about--company-entry {
		@include tablet() {
			margin-bottom: 5rem;
		}
		
		.is-6 {
			position: relative;
		}
		@include tablet() {
			&:nth-child(odd) {
				.columns {
					flex-direction: row-reverse;
				}
				
			}
			&:nth-child(even) {
				.body {
					margin-left: 30px;
				}
			}
		}
		
		.body {
			padding: 0.75rem;
			@include tablet() {
				position: absolute;
				top: 50%;
				left: 0;
			}
		}
		.description {
			@include tablet() {
				width: 75%;
			}
		}
		.title {
			background: $primary-accent;
			display: inline-block;
			padding: 20px 80px 20px 20px;
			transform: translateX(-20px);
			h1,h2,h3,h4,h5 {
				color: white;
				margin: 0;
			}
		}
		
		
		
		&:nth-child(odd) {
			.body {
				transform: translateY(-50%) translateX(-50px);
			}
			img {
				transform:  translateX(50px);
			}
		}
		&:nth-child(even) {
			.body {
				transform: translateY(-50%) translateX(50px);
			}
				img{
					transform:  translateX(-50px);
				}
			}
		.body {
			opacity: 0;
			transition: transform 0.3s linear, opacity 0.3s linear;
		}
		img{
			opacity : 0;
			transition: transform 0.3s linear, opacity 0.3s linear;
		}
		&.in {
			opacity: 1;
			transform:  translateX(0px);
			.body{
				opacity: 1;
				transform: translateY(-50%) translateX(0px);
			}
			img {
				opacity: 1;
					transform:  translateX(0);

			}
		}

}





	// .columns {
	// 	margin-bottom: 0 !important;
	// }
	// .image {
	// 	height: 50vh;
	// 	overflow: hidden;
	// 	position: relative;
	// 	background-size: cover;
	// 	img {
	// 		position: absolute;
	// 		visibility: hidden;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translate(-50%,-50%);
	// 		height: 100%;
	// 		width: auto;
	// 	}
	// }
	// .description {
	// 	height: 50vh;
	// 	position: relative;
	// 	background: $primary;
	// 	color: white;
	// 	.description--inner {
	// 		position: absolute;
	// 		width: 50%;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translate(-50%,-50%);
	// 		h1,h2,h3,h4,h5 {
	// 			color: white;
	// 			font-size: 2vw;
	// 			margin-bottom: 0.5em;
	// 		}

	// 	}
	// }
}