
.ccm-pagination-wrapper {
  display: block;
  text-align: center;
  margin-bottom: 50px;
  .pagination {
    display: inline-block;
  }
}

.pagination {
	>li {
		>a, >span {
			color: $primary;
			&:hover, &:focus {
				color: $primary;
			}
		}
	}
	>.active {
		>span, a:hover, a:focus, span:focus, span:hover {
			background: $primary;
			border-color: $primary;
		}
	}
}