
.footer {
	padding: 100px 20px;
	color: white;
   background-image: linear-gradient(-127deg, #003952 2%, #0F5582 100%);
      
   strong, a, address {
   	color: white;
      font-style: normal;
   }
   ul.nav {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
   	background: transparent;
      display: block;
      padding: 20px 0;
      height: auto;
      @include mobile() {
          border-bottom: thin solid rgba(255,255,255,0.3);
      }
     
   	li {
         display: block;
         text-align: left;
         height: auto;
         width: 100%;
         @include tablet() {
            width: auto;
            display: inline-block;
         }
   		a {
   			padding: 10px 20px 10px 0px;
            display: block;
   			color: white;
   			font-weight: 700;
   			letter-spacing: 2px;
   		}
         @include tablet() {
      		&:first-child {
      			a {
      				padding-left: 0;
      			}
      		}
         }
   	}
   }

   .ccm-block-social-links {
      padding: 20px 0;
      @include mobile() {
          border-bottom: thin solid rgba(255,255,255,0.3);
      }
      ul {
         li {
            a {
               display: block;
               width: 40px;
               height: 40px;
               text-align: center;
               line-height: 40px;
               background: transparent;
               border: thin solid white;
               color: $primary;
               border-radius: 40px;
               i {
                  color: white;
                  transform: translateX(1px);
                  line-height: 40px;
                  font-size: 18px;
               }
            }
         }
      }
   }
}