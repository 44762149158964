.page-template-contact {
	.text-muted {
		color: #999;
		font-size: 12px;
		float: right;
	}
	form {
		margin-top: 50px;
	}
}

.contact--icon {
	text-align: center;
	margin-top: 50px;

	.contact--icon-inner {
		background: $primary-accent;
		display: inline-block;
		margin: 0 auto;
		width: 100px;
		position: relative;
		text-align: center;
		border-radius: 100px;
		height: 100px;
		border: thin solid $primary;
	}
	.fa {
		color: white;
		display: block;
		font-size: 42px;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		transform: translate(-50%, -50%);
	}
	a, address {
		margin-top: 30px;
		display: block;
	}
}
.contact--map {
	position: relative;
	.caption {

	}
}

.contact--details {
	.contact--icon {
		&:nth-child(0) {transition-delay: 0.15s;}
		&:nth-child(1) {transition-delay: 0.3s;}
		&:nth-child(2) {transition-delay: 0.45s;}
	}
	.contact--icon, h1 {
		opacity: 0;
		transition: all 0.2s ease;
		transform: translateY(20px);
	}
	&.in {
		.contact--icon, h1 {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.contact--form {
		opacity: 0;
		transition: all 0.2s ease;
		transform: translateY(20px);
	&.in {
		opacity: 1;
		transform: translateY(0);
	}
}