.tabs {
	position: relative;
	&:before {
		content: ' ';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		z-index: 1;
		background: $dark-gray;
	}
	li {
		position: relative;
		&.is-active {
			&:after {
				content: ' ';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				z-index: 2;
				background: $primary;
			}
		}
		a {
			font-weight: 700;
			font-size: 16px;
			padding: 10px 40px 10px 0px;
		}
	}
}

.tab--content {
	display: none;
	&.is-active {
		display: block;
	}
}