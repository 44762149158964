.product--slider {
	display: flex;
	.slider--main {
		width: 88%;
	}
	.slider--nav {
		width: 12%;
		img {
			width: 100%;
			height: auto;
		}
	}
	.slick-slide {
		img {
			width: auto;
			margin: 0 auto;
		}
	}
}

.gallery--slider {
	.slick-slider {
		.slick-prev, .slick-next {
			&:before {
				font: normal normal normal 32px/1 FontAwesome;
		    color: $primary;
			}
		}
		.slick-prev {
			&:before {
				content: '\f104';
			}
		}
		.slick-next {
			&:before {
				content: '\f105';
			}
		}
	}
}

.slider--nav {
	.slick-slide {
		margin: 10px 10px 0 0px;
		cursor: pointer;
		opacity: 0.4;
		&.slick-current, &:hover, &:focus {
			opacity: 1;
			outline: none;
		}
	}
}




.slick-slider:not(.slick-initialized) {
    div:not(:first-child) {
        display: none;
    }
}
.slick-slider{
    opacity: 0;
    transition: opacity 1s ease;
    &.slick-initialized {
        opacity: 1;
    }
}