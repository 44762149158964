.team--member-list {
	// margin: 100px 0;
}

.team--member {
		margin: 0px 0 10px;
		position: relative;
		overflow: hidden;
		.caption {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			background-image: linear-gradient(180deg, rgba($primary-accent, 0.8) 0%, rgba(darken($primary-accent, 10%), 0.8) 100%);
			transition: all 0.2s linear;
			overflow: hidden;
			cursor: pointer;
			font-size: 14px;
			
			.caption--inner {
				padding: 30px 15px;
				text-align: center;
			}
			.image {
				transition: all 0.2s 0.2s linear;
			}
			.name {
				h4, h3 {
					color: white;
					transition: color 0.2s linear;
				}
			}
			.role, .description {
				color: white;
				transform: translateY(20px);
				transition: all 0.2s linear;
				opacity: 0;

			}
			.role {
				text-transform: uppercase;
				font-weight: 300;
				transition-delay: 0.2s;
			}
			.description {
				display: none;
				transition-delay: 0.3s;
			}
			blockquote {
				background: transparent;
				color: white;
				border-left: 0;
				font-style: italic;
			}
			.contact {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
			.email, .phone {
				color: white;
				padding: 10px;
				a {
					color: white;
				}

			}
			.email {
				border-bottom: thin solid rgba(255,255,255,0.2);
				border-top: thin solid rgba(255,255,255,0.2);
			}
			.phone {

			}

		}
		&:hover {
			.image {
				filter: blur(10px);
			}
			.caption {
				opacity: 1;
			}
			.name {
				h4 {
					color: white;
				}
			}
			.description, .role {
				opacity: 1;
				transform: translateY(0);
			}
		}
}