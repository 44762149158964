.editing {
	.header {
		top: 50px;
	}
}
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;
}
.header--container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 20;
	display: flex;

	background: white;
	@include desktop() {
		position: initial;
		top: 0 !important;
		background: white;
	}
	
	.logo {
		display: inline-block;
		padding: 20px;
		@include tablet() {
			padding: 30px;
		}
		
	}

	.mobile--toggle {
		margin-left: auto;
		@include desktop() {
			display: none;
		}
		a {

			line-height: 87px;
  		padding: 0px 30px;
  		text-transform: uppercase;
  		letter-spacing: 2px;
  		@include tablet {
				line-height: 107px;
  		}
		}
	}
	.navigation--container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: white;
		z-index: 10;
		opacity: 0;
		visibility: hidden;
		transform: opacity 0.2s linear, visibility 0.2s linear;

		@include desktop() {
			display: flex;
			margin-left: auto;
			position: inherit;
			width: auto;
			height: auto;
			opacity: 1;
			visibility: visible;
		}
		.language--container {
			padding: 5px 20px;
			margin-left: auto;
			border-left: thin solid $gray;
			.language--dropdown {
				display: flex;
		    align-items: center;
		    height: 100%;
		    flex-direction: column;
		    justify-content: center;
				li {
					a {
						// padding: 0px 20px;
						width: 20px;
						display: block;
						font-size: 14px;
					}
					&.active {
						a {
							color: $info;
						}
					}
				}
			}
		}
	}
	&.is-open {
		.navigation--container {
			opacity: 1;
			visibility: visible;
		}
	}
	
	
	.navigation--inner {
		border-left: thin solid $gray;
		@include desktop() {
			display: flex;
			flex-direction: column;
			height: 100%;
			.top--bar, .navigation {
				height: 50%;
			}
		}
		
	}
	.top--bar {
		padding: 10px 30px;
		text-align: center;
		font-size: 12px;
		letter-spacing: 3px;
		vertical-align: middle;
		@include widescreen() {
			font-size: 14px;
		}
		
		>span {
			display: inline-block;
			height: 100%;
			font-size: 12px;
			line-height: 2.6em
		}
	}
	.navigation {
		border-top: thin solid $gray;
		text-align: center;
		padding: 0 10px;
		@include widescreen() {
			padding: 0 50px;
		}
		

		ul.nav--list {
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			@include desktop() {
				flex-direction: row;
			}
			>li {
				display: inline-block;
				>a {
					position: relative;
					color: $primary;
					line-height: 2.3em;
					transition: all .2s linear;
					display: block;
					padding: 10px 40px;
					font-weight: 700;
					font-size: 12px;
					text-transform: uppercase;
					letter-spacing: 2px;
					@include widescreen() {
						font-size: 14px;
					}
					i.fa {
						position: absolute;
						left: 50%;
						bottom: 5px;
						transform: translateX(-50%);
					}
				}
				&.has-dropdown {
					>a {
						&:after {
							content: "\f107";
							position: absolute;
							display: inline-block;
					    font: normal normal normal 14px/1 FontAwesome;
					    font-size: inherit;
					    text-rendering: auto;
					    -webkit-font-smoothing: antialiased;
					    -moz-osx-font-smoothing: grayscale;
					    bottom: 5px;
					    left: 50%;
					    transform: translateX(-50%);
						}
					}
				}	
				ul.dropdown {
					visibility: hidden;
			    opacity: 0;
			  	z-index: 10;
			    transition: all .2s linear;
			    position: absolute;
			    left: 0;
			    width: 100%;
			    background: $light-gray;
			    text-align: right;
			    justify-content: flex-end;
			    li {
			    	display: inline-block;
			    	a {
			    		
			    		position: relative;
							color: $primary;
							line-height: 2.3em;
							transition: all .2s linear;
							display: block;
			    		padding: 10px 20px;
							font-weight: 400;
							font-size: 14px;
							text-transform: uppercase;
							letter-spacing: 2px;
			    	}
			    }
				}
				&:hover {
					>a {
						background: $light-gray;
					}
					ul.dropdown {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			
		}
	}
}


.mobile--nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 15;
	background: $gray;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s linear, visibility 0.2s linear, transform 0.2s linear;
	
	>ul {
		position: absolute;
		top: 89px;
		@include tablet() {
			top:110px;
		}
		left: 50%;
		transform: translateX(-50%) ;
		width: 100%;
		>li {
			display: block;
			transition: transform 0.2s linear, opacity 0.2s linear;
			transform: translateY(30px);
			opacity: 0;

			>a {
				font-size: 18px;
				font-weight: 700;
				letter-spacing: 3px;
				display: block;
				padding: 10px 40px;

			}
			&:nth-child(odd) {
				background: rgba(0,0,0,0.05);
			}
			.dropdown {
				display: none;
				padding-left: 50px;
				padding-top: 5px;
				padding-bottom: 5px;
				li {
					a {
						padding: 5px 0;
						font-size: 14px;
						display: block;	
					}
				}
			}


			&.has-dropdown {
				>a {
					position: relative;
					&:after {
						content: '\f105';
						display: block;
						font-family: FontAwesome;
						font-size: 18px;
						position: absolute;
						top: 50%;
						right: 40px;
						transform: translateY(-50%);
						transition: transform 0.3s ease;
					}	
				}
			}

			&.is-open {
				>a{
				&:after {
					transform: translateY(-50%) rotate(90deg);
				}
				}
				.dropdown {
					display: block;
				}
			}

		}


	}
	&.is-open {
		visibility: visible;
		opacity: 1;
		>ul {
			li {
				@for $i from 1 through 10 {
					 &:nth-child(#{$i})
	        {
              transition-delay: #{0.1 * $i}s;
	        }
        }
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
	.mobile--language-select {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px;
			background: white;
			.language--dropdown {
				display: flex;
				justify-content: center;
				li {
					margin: 0 10px;
				}
			}
		}
}