body {
	font-family: 	"Open Sans", sans-serif;
	font-size: 16px;
	letter-spacing: 2px;
	line-height: 1.6em;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 	"Open Sans", sans-serif;
	letter-spacing: 0.1em;
	font-weight: 700;
	line-height: 1.6em;
	color: $primary;
}
.content {
	h1,h2,h3,h4,h5,h6 {
		font-family: 	"Open Sans", sans-serif;
		letter-spacing: 0.1em;
		font-weight: 700;
		line-height: 1.6em;
		color: $primary;
	}
}

h1 {
	font-size: 3rem;
	margin-bottom: 1rem;
}
.content {
	h1 {
		font-size: 2rem;
		@include tablet() {font-size: 3rem;}
	}
	h2 {
		font-size: 2.4rem;
	}
	h3 {
		font-size: 2.0rem;
	}
	h4 {
		font-size: 1.6rem;
	}
	h5 {
		font-size: 1.3rem;
	}
}
