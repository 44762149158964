@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.header {
		position: relative;
		.header--container {
			position: relative;
		}
	}
	img {
		border: 0;
	}

	.ie-tile-tall {
		height: 770px;
	}
}