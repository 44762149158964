.industry--slider {
	margin-bottom: 150px !important;
	margin-top: 150px;
	.column{
		position: relative;
		.ccm-image-slider-text {
			@include mobile() {
				margin: 0 20px 30px;

			}
			@include tablet() {
				padding-left: 40px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}

	.slick-dots {
		top: -100px;
		margin: 0;
		li {
			width: auto;
			height: auto;
			margin-top: 10px;
			&.slick-active {
				.button {
					background: $primary;
					color: white;
				}
			}
			button {
				&:before {
					content: ' ';
					border-radius: 0px;
					width: 60px;
					height: 10px;
					background: $primary;;
					border: thin solid rgba(255,255,255,0.8);
				}
			}
		}
	}
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}