.news--entry {
	padding: 100px 0;
	opacity: 0;
	transform: translate(0px, 50px);
	transition: all 0.2s ease;
	&.in {
		transform: translate(0,0);
		opacity: 1;
	}
	&:nth-child(odd) {
		background: white;
	}
	.column {
		position: relative;
	}
	.description {
		padding-left: 20px;
		padding-right: 20px;
		@include tablet() {
			padding-left: 0;
			padding-right: 20px;
			top: 50%;
			position: absolute;
			transform: translate(0, -50%);
		}
		
		h3 {
			margin-top: 10px;
			font-size: 20px;
			@include tablet() {
				font-size: 2rem;
			}
		}
		.date {
			// margin-bottom: 10px;
			font-size: 12px;
		}
	}
}


.news--entry-banner {
	text-align: center;
}