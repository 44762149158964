.product--details {
	.product--description {
		margin-bottom: 25px;
		padding-bottom: 25px;
	}
	.product--image {
		.section--title {
			h2 {
				font-size: 24px;
				margin-top: 30px;
				margin-bottom: 30px;
			}
		}
	}
	.product--tabs, .product--description, .breadcrumbs {
		@include mobile() {
			padding-left: 20px;
			padding-right: 20px;
			h1 {
				font-size: 2rem;
			}
		}
	}
	.product--enquiry {
		margin-top: 50px;
		@include mobile() {
			padding-left: 20px;
			padding-right: 20px;
		}
		.nav {
			background: transparent;
    margin-top: 30px;
    font-weight: 800;
    li {
    	a {
    		position: relative;
    		&:after {
    			
    		}
    	}
    }
		}
	}

	.tab--content {
		ul {
			list-style: initial;
			margin: 0 20px;
		}
	}

	.gallery--slider {
		margin-top: 30px;
		@include tablet() {
			margin-top: 70px;
		}
		
		text-align: center;
		.slick-slide {
			margin: 0 10px;
		}
		h1,h2,h3, h4 {
			margin-bottom: 30px;
			font-size: 24px;
		}
	}

}

.product--slider {
	@include mobile() {
		padding: 0 20px;
	}
}

.product--related-list {
	background: $light-gray;

}
.product--related {
	display: block;
	@include mobile() {
		margin: 20px;
	}
	img {
		width: 100%;
	}
	.product--related-title {
			display: block;
			position: relative;
			padding: 20px 0;
			margin-bottom: 20px;
			h1,h2,h3,h4 {
				font-size: 24px;
				color: $primary;
				font-weight: 700;
				transition: color 0.15s linear;
				margin-bottom: 0;
			}
			i.fa {
				position: absolute;
				transition: right 0.15s 0.15s linear, color 0.15s 0.15s linear;
				color: $primary;
				top: 50%;
				line-height: 1;
				right: 10px;
				transform: translateY(-50%);
			}
			&:after, &:before {
				content: ' ';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0%;
				height: 1px;
				transition: width 0.3s linear;
			}
			&:before {
				width: 100%;
				background: darken($gray, 10%);
			}
			&:after {
				background: $primary-accent;
			}
		}
		&:hover {
			
			.product--related-title {
				h1,h2,h3,h4,h5 {
					color: $primary-accent;
				}
				i.fa {
					color: $primary-accent;
					right: 0;
				}
				&:after {
				width: 100%;
			}
			}
		}
}


.product--modal {
	@include tablet() {
		max-width: 1000px !important;
	padding: 50px !important;
	text-align: left;
	}

	.product--modal-description {
		@include tablet() {
			padding-left: 30px;
		}
		
		h3 {
			font-size: 32px;
		}
	}
	@include mobile() {
		img {
			display: none;
		}
	}
	
}